<template>
  <div>
    <v-row>
      <v-col class="pa-0 ma-0" md="12" lg="6" sm="12">
        <p class="textOrange--text">EVALUATION</p>
        <div class="deadline-card">
          <div class="deadline-container">
            <v-menu
              v-model="deadLine"
              :close-on-content-click="false"
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="ma-0 pa-0"
                  label="Evaluation Deadline"
                  append-icon="mdi-calendar"
                  v-model="textDate"
                  readonly
                  :value="toDateDisp"
                  :rules="requiredRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="en-in"
                v-model="evaluationData.date"
                no-title
                @input="deadLine = false"
                :min="minDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>

        <div class="invite-card">
          <div class="invite-header d-flex" @click.stop="inviteDialog = true">
            <v-icon left color="primary">mdi-plus</v-icon>
            <p class="mt-4 body-1 primary--text">Invite Evaluators</p>
          </div>

          <div class="invite-list-container">
            <div v-for="(evaluator, index) in evaluators" :key="index">
              <invite-card
                :evaluator="evaluator"
                :index="index"
                @removeEvaluator="removeEvaluators($event)"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="pa-0 ma-0 mt-7" md="12" lg="6" sm="12">
        <div class="add-criteria">
          <div class="add-criteria-header d-flex" @click.stop="addCriteriaDialog = true">
            <v-icon left color="primary">mdi-plus</v-icon>
            <p class="mt-5 body-1 primary--text">Add Criteria</p>
          </div>
          <v-card flat max-height="410" class="overflowVertical pb-6">
            <div v-for="(criteria, index) in criterias" :key="index">
              <criteria-card
                :criteria="criteria"
                :index="index"
                @removeCriteria="removeCriteria($event)"
              />
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="addCriteriaDialog" width="350">
      <v-card height="380">
        <v-card-title
          class="headline grey primary d-flex justify-center white--text pb-5"
        >Add Criteria</v-card-title>

        <v-form
          class="d-flex justify-center flex-column align-center"
          @submit.prevent="addCrieteria"
          ref="form"
        >
          <div class="form-container">
            <v-text-field
              :rules="[v => !!v || 'Criteria is required']"
              maxlength="25"
              v-model="description"
              :counter="25"
              label="Criteria"
            ></v-text-field>
            <p>Choose Rating</p>

            <v-radio-group v-model="radioGroup" row>
              <v-radio label="1-10" :value="'NUMBER'"></v-radio>
              <v-radio label="Yes/No" :value="'BOOLEAN'"></v-radio>
            </v-radio-group>
          </div>

          <div class="button-container">
            <v-btn
              color="textOrange"
              title="Submit"
              type="Submit"
              dark
              block
              elevation="0"
            >Add Criteria</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="inviteDialog" width="350">
      <v-card height="300">
        <v-card-title
          class="headline grey primary d-flex justify-center white--text pb-5"
        >Invite Evaluators</v-card-title>

        <v-form class="d-flex justify-center flex-column align-center" ref="form">
          <div class="form-container">
            <v-text-field
              @blur="$v.email.$touch"
              required
              v-model="email"
              label="Email"
              placeholder="abebe@gmail.com"
              :error-messages="emailErrorMessages"
            ></v-text-field>
          </div>

          <div class="button-container">
            <v-btn
              color="textOrange"
              title="Submit"
              :loading="inviteLoading"
              @click="sendEvaluatorInvite"
              dark
              block
              elevation="0"
            >Invite Evaluators</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { roles } from "@/shared/constants/roles";
import { eventBus } from "@/main";
import InviteCard from "./InviteCard";
import CriteriaCard from "./CriteriaCard";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "AddEvaluators",
  props: ["courseData"],
  components: {
    InviteCard,
    CriteriaCard
  },
  data() {
    return {
      deadLine: false,
      addCriteriaDialog: false,
      criteriaIds: [],
      evaluatorsIds: [],
      email: "",
      emails: null,
      radioGroup: null,
      inviteDialog: false,
      inviteLoading: false,
      checkbox1: false,
      checkbox2: false,
      description: null,
      minDate: "2020-01-01",
      requiredRules: [v => !!v || "Field is required"],
      textDate: null,
      evaluationData: {
        date: null
      },
      criterias: [],
      evaluators: []
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  created() {
    this.getAvailableCriteria();
    this.getAllEvaluators();
    this.evaluationData.date = this.courseData.evaluationDeadline;
  },
  computed: {
    ...mapFields("training", ["allCriteria"]),
    ...mapFields("auth", ["currentUser"]),
    toDateDisp() {
      if (this.evaluationData.date === this.courseData.evaluationDeadline) {
        this.textDate = moment(this.evaluationData.date).format("L");
        /*Do not make API call if fetched Date is not changed */

        return;
      } else {
        this.setEvaluationDeadline();
      }
      return this.evaluationData.date;
      // formated date mm/dd/yyyy
    },
    emailErrorMessages() {
      const errors = [];
      if (!this.$v.email.$dirty) return [];
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Please enter a valid email address");
      return errors;
    }
  },
  methods: {
    ...mapActions("training", {
      addNewCriteria: "addNewCriteria",
      fetchAvailableCriteria: "fetchAvailableCriteria",
      removeCriterion: "removeCriterion",
      evaluationDeadline: "evaluationDeadline",
      inviteNewEvaluator: "inviteNewEvaluator",
      removeEvaluator: "removeEvaluator"
    }),
    getAllEvaluators() {
      let evaluatorsList = this.courseData.evaluators;
      let pendingEvaluatorsList = this.courseData.pendingEvaluators;

      if (evaluatorsList) {
        if (evaluatorsList.length > 0) {
          evaluatorsList.map(element => {
            this.evaluators.splice(0, 0, {
              initial: element.firstName.charAt(0) + element.lastName.charAt(0),
              name: element.firstName + " " + element.lastName,
              email: element.email
            });
          });
        }
      }
      if (pendingEvaluatorsList) {
        if (pendingEvaluatorsList.length > 0) {
          pendingEvaluatorsList.map(element => {
            this.evaluators.splice(this.evaluators.length, 0, {
              initial: element.charAt(0),
              name: "Pending ...",
              email: element
            });
          });
        }
      }
    },

    async setEvaluationDeadline() {
      try {
        const deadline = await this.evaluationDeadline({
          courseId: this.courseData.courseId,
          deadline: this.evaluationData.date
        });

        this.textDate = moment(this.evaluationData.date).format("L");
      } catch (error) {
        console.log(error.message);
      }
    },

    async removeEvaluators(index) {
      this.evaluatorsIds.splice(index, 1);
      let patchData = {
        courseId: this.courseData.courseId,
        evaluatorsIds: this.evaluatorsIds
      };
      try {
        const removed = await this.removeEvaluator({
          patchData: patchData
        });
        if (removed) {
          this.evaluators.splice(index, 1);
          eventBus.$emit("DeleteEvent");
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    async sendEvaluatorInvite() {
      this.inviteLoading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.notify("error", "Please Check the Email Field!");
        this.inviteLoading = false;
        return !this.$v.$invalid;
      } else {
        try {
          const invited = await this.inviteNewEvaluator({
            email: this.email,
            accountId: this.currentUser.accounts[0],
            courseId: this.courseData.courseId,
            assignedRole: roles.COURSE_EVALUATOR
          });

          this.evaluators.splice(0, 0, {
            initial: invited.email.charAt(0),
            name: "Pending",
            email: invited.email
          });
        } catch (error) {
          console.log(error.message);
        } finally {
          this.email = "";
          this.inviteLoading = false;
          this.inviteDialog = false;
        }
      }
    },
    async getAvailableCriteria() {
      try {
        if (this.courseData.criteria.length > 0) {
          this.courseData.criteria.map(element => {
            this.criterias.push({
              description: element.description,
              ratingType: element.ratingType,
              id: element._id
            });
            this.criteriaIds.push(element._id);
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async addCrieteria() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.addCriteriaDialog = false;
        const criteria = await this.addNewCriteria({
          courseId: this.courseData.courseId,
          description: this.description,
          ratingType: this.radioGroup
        });
        this.criterias.push({
          description: criteria.description,
          ratingType: criteria.ratingType,
          id: criteria._id
        });
        this.criteriaIds.push(criteria._id);
        this.description = "";
      } catch (error) {
        console.log(error.message);
      }
    },
    async removeCriteria(index) {
      this.criteriaIds.splice(index, 1);
      let patchData = {
        courseId: this.courseData.courseId,
        criteriaIds: this.criteriaIds
      };
      try {
        const removed = await this.removeCriterion({
          patchData: patchData
        });
        if (removed) {
          this.criterias.splice(index, 1);
          eventBus.$emit("DeleteEvent");
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  }
};
</script>

<style scoped>
.deadline-card {
  height: 97px;
  width: 422px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000017;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}
.deadline-container {
  width: 80%;
  margin-top: 25px;
}

.invite-card {
  margin-top: 40px;
  min-height: 300px;
  width: 422px;
  padding-bottom: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000017;
  border-radius: 6px;
}
.invite-header {
  height: 60px;
  margin: 10px 30px;
  cursor: pointer;
}
.invite-list-container {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-2 {
  margin-right: 500px;
}
.add-criteria {
  height: 294px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000017;
  border-radius: 6px;
  margin-left: 20px;
  width: 400px;
}

.add-criteria-header {
  height: 60px;
  margin: 10px 30px;
  cursor: pointer;
}
.form-container {
  display: flex;
  margin-top: 30px;

  width: 282px;
  flex-direction: column;
  align-content: center;
}

.button-container {
  width: 80%;
  margin-top: 30px;
}
.overflowVertical {
  overflow-y: auto;
}
</style>