<template>
  <v-container fluid class="ma-0 pa-0">
    <p class="textOrange--text px-2">General</p>
    <v-row class="ma-0 pa-0">
      <v-col cols="6" class="ma-0 py-0 px-2">
        <v-card class="card-shadow ma-0 pa-0 d-flex flex-column" width="457" align="left">
          <v-form ref="form" class="px-2 pt-4 ma-0">
            <v-text-field
              :rules="requiredRules"
              v-model="courseData.name"
              label="Course Name"
              class="px-4 ma-0 py-1"
            ></v-text-field>
            <v-text-field
              :rules="requiredRules"
              v-model="courseData.description"
              label="Course Description"
              class="px-4 ma-0 py-1"
            ></v-text-field>
            <v-row class="d-flex justify-space-between px-4 ma-0 py-1">
              <v-col class="ma-0 pa-0" cols="5">
                <v-menu
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="ma-0 pa-0"
                      label="Start Date"
                      append-icon="mdi-calendar"
                      readonly
                      :rules="requiredRules"
                      :value="fromDateDisp"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="en-in"
                    v-model="courseData.startDate"
                    no-title
                    @input="fromDateMenu = false"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="ma-0 pa-0" cols="5">
                <v-menu
                  v-model="toDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="ma-0 pa-0"
                      label="End Date"
                      append-icon="mdi-calendar"
                      readonly
                      :rules="requiredRules"
                      :value="toDateDisp"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="en-in"
                    v-model="courseData.endDate"
                    no-title
                    @input="toDateMenu = false"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-select
              v-model="courseData.prerequisiteCourseIds"
              :items="courses"
              class="px-4 mx-0 my-1 py-0"
              label="Prerequisite Courses"
              multiple
              chips
              deletable-chips
              item-text="name"
              item-value="_id"
            ></v-select>
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-select
                  class="px-4 mx-0 my-1 py-0"
                  label="Course Level"
                  :items="courseLevel"
                  v-model="courseData.level"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-card
          :loading="loadDis"
          class="card-shadow mx-0 my-4 pa-0 d-flex flex-row"
          width="457"
          align="left"
        >
          <v-icon
            v-if="imageUrl == ''"
            @click="onfileselected"
            size="40"
            class="pa-6 ma-0"
            style="background: #FE664E"
            color="textLight"
          >mdi-camera</v-icon>
          <v-img class="ma-0 pa-0" v-else :src="imageUrl" max-height="94" max-width="88">
            <v-row justify="end">
              <v-icon size="30" class="pr-4" color="textLight" @click="reselectPhoto">mdi-camera</v-icon>
            </v-row>
          </v-img>
          <v-row class="ma-0 pa-0">
            <v-col cols="9">
              <v-text-field
                :rules="requiredRules"
                v-model="courseData.instructorName"
                label="Instructor Name"
                class="mx-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <input type="file" id="logo" style="display:none;" accept="image/*" ref="onfile" />
        <avatar-cropper
          v-show="showCropper"
          @changed="handleChange"
          :trigger="avatarTrigger"
          :labels="avatarCropper.labels"
          :cropper-options="avatarCropper.options"
          :output-mime="avatarCropper.mime"
          :upload-handler="uploadAvatar"
        />
      </v-col>
      <v-col cols="6" class="ma-0 py-0 px-2">
        <v-card class="card-shadow ma-0 pa-0 d-flex flex-column scroll" width="424" height="300">
          <div class="px-2 pt-4 ma-0">
            <v-text-field
              @keypress.enter="addToAgenda()"
              label="Enter agenda"
              v-model="agenda"
              class="px-4 ma-0 py-1"
            ></v-text-field>
            <v-card
              class="card-shadow mx-4 mb-3 pa-2 d-flex flex-row justify-space-between"
              v-for="(agenda, index) in courseData.agendas"
              :key="index"
            >
              <p class="pa-0 ma-0">{{ courseData.agendas[index] }}</p>
              <v-btn small icon @click="removeAgenda(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card>
            <v-card
              color="transparent"
              class="d-flex align-center justify-center"
              flat
              height="150"
              v-if="courseData.agendas.length == 0"
            >
              <v-card-text class="d-flex justify-center body-1">No agendas to preview</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.scroll {
  overflow-y: auto;
}
</style>

<script>
import { required } from "vuelidate/lib/validators";
import AvatarCropper from "vue-avatar-cropper";
import { mapActions } from "vuex";
import { eventBus } from "@/main";

export default {
  name: "FillGeneralInfo",
  components: {
    AvatarCropper
  },
  data() {
    return {
      fromDateMenu: false,
      toDateMenu: false,
      minDate: "2020-01-01",
      agenda: "",
      imageUrl: "",
      photoId: null,
      imageLoaded: false,
      requiredRules: [v => !!v || "Field is required"],
      courseLevel: ["None", "Begineer", "Intermediate", "Advanced"],
      showCropper: false,
      avatarTrigger: "#logo",
      loadDis: false,
      avatarCropper: {
        labels: {
          submit: "Done",
          cancel: "Cancel"
        },
        options: {
          aspectRatio: 1
        },
        mime: "image/jpeg"
      },
      courses: []
    };
  },
  validations: {
    courseData: {
      name: {
        required
      },
      description: {
        required
      },
      startDate: {
        required
      },
      endDate: {
        required
      },
      instructorName: {
        required
      }
      // courseId: {
      //   required
      // }
    }
  },
  watch: {
    $v: {
      deep: true,
      handler(value) {
        this.$emit("formInvalid", value.$invalid);
      }
    }
    // this.snackbar = true;
  },
  async created() {
    eventBus.$on("imageIdFoundEvent", imageId => {
      this.photoId = imageId;
      this.getInstructorImage();
    });

    this.getInstructorImage();
    const { courses } = await this.getTraining({
      trainingId: this.$route.query.trainingId
    });
    if (courses) {
      this.courses = courses.filter(
        course => course._id != this.$route.query.courseId && course.isComplete
      );
    }
    this.courseData.courseLevel = "None";
  },
  methods: {
    ...mapActions("admin", {
      uploadFile: "uploadFile",
      fetchFilesUploaded: "fetchFilesUploaded"
    }),
    ...mapActions("training", {
      getTraining: "getTraining"
    }),
    async getInstructorImage() {
      try {
        this.imageUrl = this.courseData.instructorPictureUrl;
        this.imageLoaded = true;
      } catch (error) {
        console.log(error.message);
      }
    },
    addToAgenda() {
      if (this.agenda == "") {
        return;
      }
      this.courseData.agendas.push(this.agenda);
      this.agenda = "";
    },
    removeAgenda(index) {
      this.courseData.agendas.splice(index, 1);
    },
    handleChange(file) {
      if (file.type.split("/")[0] != "image") {
        this.notify("error", "An unexpected error has occured");
        this.correctFormat = false;
        this.showCropper = false;

        return;
      } else {
        this.image = file;
        this.correctFormat = true;
        this.showCropper = true;
      }
    },
    uploadAvatar(file) {
      if (this.correctFormat) {
        file.getCroppedCanvas().toBlob(blob => {
          if (blob !== null) {
            this.avatarBlob = blob;
            this.uploadImage();
          }
        });

        this.selectedAvatarImage = file
          .getCroppedCanvas()
          .toDataURL(this.avatarCropper.mime);
      } else {
        return;
      }
    },
    onfileselected() {
      this.$refs.onfile.click();
    },
    reselectPhoto() {
      this.onfileselected();
    },
    async uploadImage() {
      if (this.image == null) {
        return;
      }
      this.loadDis = true;
      try {
        const uploaded = await this.uploadFile({
          fileData: this.avatarBlob
        });
        this.courseData.instructorPictureId = uploaded[0]._id;
        this.imageUrl = uploaded[0].url;
        this.courseData.instructorPictureUrl = this.imageUrl;
      } catch (error) {
        console.log(error.message);
      } finally {
        this.loadDis = false;
      }
    }
  },
  computed: {
    fromDateDisp() {
      return this.courseData.startDate;
      // format/do something with date
    },
    toDateDisp() {
      return this.courseData.endDate;
      // format/do something with date
    }
  },
  props: ["courseData"]
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0px 0px 75px #00000024;
}
</style>
