<template>
  <div class="ma-0 pa-0">
    <admin-header :title="'Add Course'" :links="bcLinks"></admin-header>
    <v-btn v-if="canDownload" @click="downloadExcelFile" class="ma-6 textOrange textLight--text">
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-row class="ma-0 pa-0 ml-12 mb-12">
      <v-col cols="3" class="ma-0 pa-0">
        <div>
          <p class="textDark--text body-1 pb-0">Steps</p>
        </div>
        <v-stepper v-model="onStep" vertical class="stepper-contianer elevation-0">
          <div v-for="(step, index) in steps" :key="index">
            <v-stepper-step
              class="px-0 py-1"
              :step="step.step"
              :complete="step.step < onStep ? true : false"
            >
              <div
                flat
                :class="step.step <= onStep ? 'primary--text' : ''"
                class="ma-0 pa-0 divCursor"
                @click="previousStep(step.step)"
              >{{step.label}}</div>
            </v-stepper-step>
            <v-divider v-if="index < 3" class="mx-3 primary" style="height:45px" vertical></v-divider>
          </div>
        </v-stepper>
      </v-col>
      <v-col cols="9" class="ma-0 pa-0">
        <component
          :is="findNextComponent()"
          :courseData="courseData"
          v-on:formInvalid="setDisabled($event)"
        ></component>
      </v-col>
    </v-row>
    <bz-notification v-model="notification"></bz-notification>
    <!-- <p class="my-12 pb-12">{{courseData}}</p> -->
    <!-- @shareBeezIn="" -->
    <congratulations :display="display" :continueTo="routeTo" type="course" :beezInCode="code"></congratulations>
    <v-footer height="65" absolute class="d-flex justify-center">
      <div class="d-flex flex-row">
        <p
          class="body-1 textDark--text mr-12 my-2"
        >{{isComplete ? 'Click on Done to finish the process' : 'Click on Next to Fill the Forms'}}</p>
        <BzButton
          @click="nextStep()"
          :loading="loadingNext"
          :disabled="isDisabled"
          depressed
          class="textOrange textLight--text mx-1"
        >{{isComplete ? 'Done' : 'Next'}}</BzButton>
        <BzButton
          @click="saveChanges('loading')"
          :disabled="isDisabled"
          :loading="loading"
          depressed
          title="Save Changes"
          color="primary"
          class="primary textLight--text mx-1"
        ></BzButton>
        <v-btn
          depressed
          text
          class="mx-1"
          :to="{name: 'course-home',
            query: {
              course: headerTitle,
              id: trainingId
            }}"
        >Cancel</v-btn>
      </div>
    </v-footer>
  </div>
</template>

<script>
import BzButton from "@/shared/components/BzSubmitButton";
import FillGeneralInfo from "./components/FillGeneralInfo";
import AddMaterials from "./components/AddMaterials/index";
import AddSubmissionDetails from "./components/AddSubmissionDetails";
import AddEvaluators from "./components/AddEvaluators/index";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { eventBus } from "@/main";
import BzNotification from "@/shared/components/BzNotification";
import Congratulations from "@/beez-modules/beez-user/components/Congratulations";
import AdminHeader from "@/beez-admin/components/AdminHeader";
import moment from "moment";

export default {
  name: "AddCourse",
  data() {
    return {
      headerTitle: "Course",
      accountid: null,
      trainingId: null,
      courseId: null,
      display: false,
      onStep: 1,
      canDownload: false,
      isComplete: false,
      isDisabled: true, // true
      steps: [
        {
          label: "Fill General Info",
          step: 1
        },
        {
          label: "Add Materials",
          step: 2
        },
        {
          label: "Add Submission Details (Optional)",
          step: 3
        },
        {
          label: "Add Evaluators and Evaluation Criteria",
          step: 4
        }
      ],
      courseData: {
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        prerequisiteCourseIds: [],
        level: "",
        instructorName: "",
        instructorPictureId: "",
        instructorPictureUrl: "",
        agendas: [],
        courseId: "",
        materialIds: [],
        submissionDetails: [],
        evaluationDeadline: "",
        material: [],
        submissionSteps: [],
        criteria: [],
        evaluators: [],
        pendingEvaluators: [],
        criteria: [],
        isComplete: false
      },
      loading: false,
      loadingNext: false,
      code: "",
      isNew: true,
      isCompleteFalse: false
    };
  },
  methods: {
    ...mapActions("training", {
      createCourse: "createCourse",
      editCourse: "editCourse",
      getSpecificCourse: "getSpecificCourse",
      getTraining: "getTraining",
      downloadExcel: "generateExcel"
    }),
    async downloadExcelFile() {
      const url = await this.downloadExcel({
        courseId: this.courseData.courseId
      });
      let newTab = window.open();
      newTab.location.href = url;
    },
    async nextStep() {
      if (this.onStep == 1 && this.isNew && !this.isDisabled) {
        this.loadingNext = true;
        await this.createACourse();
        await this.patchCourse();
        this.loadingNext = false;
      }
      this.onStep += 1;
      if (this.onStep == 4) {
        if (this.courseData.isComplete == false) {
          this.isCompleteFalse = true;
        }
        this.isComplete = true;
      } else if (this.onStep == 5) {
        this.courseData.step = 5;
        this.courseData.isComplete = true;

        await this.saveChanges("loadingNext");
        if (this.isNew || this.isCompleteFalse) {
          this.display = true;
          const training = await this.getTraining({
            trainingId: this.trainingId
          });

          // let allAccounts = await this.getAllAccounts();
          // // this. getAvailableCourses();

          if (training.beezInCode) {
            let { beezInCode } = training;

            if (beezInCode !== null) {
              this.code = beezInCode;
            }
          }
        } else {
          this.$router.push({
            name: "course-home",
            query: {
              course: this.headerTitle,
              id: this.trainingId
            }
          });
        }
      }
    },
    async saveChanges(loadingVar) {
      switch (loadingVar) {
        case "loading":
          this.loading = true;
          break;
        case "loadingNext":
          this.loadingNext = true;
          break;
      }
      // this.loading = true;

      try {
        if (this.courseData.courseId) {
          await this.patchCourse();
        } else {
          await this.createACourse();
          await this.patchCourse();
        }
        this.notify("success", "Your changes have been sucessfully saved");
      } catch (error) {
        this.notify("error", "An unexpected error has occured");
      } finally {
        switch (loadingVar) {
          case "loading":
            this.loading = false;
            break;
          case "loadingNext":
            this.loadingNext = false;
            break;
        }
      }
    },
    async createACourse() {
      try {
        const course = await this.createCourse({
          name: this.courseData.name,
          description: this.courseData.description,
          trainingId: this.trainingId,
          level: this.courseData.level
        });
        this.courseData.courseId = course._id;
        this.isNew = false;
        this.$router.push({
          name: "add-course",
          query: {
            course: this.headerTitle,
            trainingId: this.trainingId,
            courseId: this.courseData.courseId
          }
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    async patchCourse() {
      const patchData = this.courseData;
      const course = await this.editCourse({ patchData });
    },
    async getTheCourses() {
      try {
        const course = await this.getSpecificCourse({
          accountid: this.accountid,
          trainingId: this.trainingId,
          courseId: this.courseId
        });
        // set the course id here for already created Course
        try {
          this.courseData.courseId = course._id;
          this.courseData.name = course.name;
          this.courseData.description = course.description;
          this.courseData.agendas = course.agenda;
          this.courseData.level = course.level;
          this.courseData.startDate = moment(course.startDate).format(
            "YYYY-MM-DD"
          );
          this.courseData.endDate = moment(course.endDate).format("YYYY-MM-DD");
          this.courseData.instructorName = course.instructorName;
          this.courseData.instructorPictureId = course.instructorPhotoId;
          this.courseData.instructorPictureUrl = course.instructorPhoto
            ? course.instructorPhoto.url
            : "";
          this.courseData.isComplete = course.isComplete;
          this.courseData.materialIds = course.materialIds;
          this.courseData.prerequisiteCourseIds = course.prerequisiteCourseIds;
          this.courseData.evaluationDeadline = course.evaluationDeadline
            ? moment(course.evaluationDeadline).format("YYYY-MM-DD")
            : "";
          this.courseData.material = course.material;
          this.courseData.submissionSteps = course.submissionSteps
            ? course.submissionSteps
            : [];
          this.courseData.criteria = course.criteria ? course.criteria : [];
          this.courseData.evaluators = course.evaluators
            ? course.evaluators
            : [];
          this.courseData.pendingEvaluators = course.pendingEvaluators;
          if (this.courseData.instructorPictureId) {
            eventBus.$emit(
              "imageIdFoundEvent",
              this.courseData.instructorPictureId
            );
          }

          if (
            moment()
              .startOf("day")
              .isAfter(moment(this.courseData.evaluationDeadline).startOf("day"))
          ) {
            this.canDownload = true;
          }
        } catch (error) {
          console.log(error);
        }
      } catch (error) {}
    },
    setDisabled(value) {
      this.isDisabled = value;
    },
    previousStep(currentStep) {
      if (currentStep < this.onStep) {
        this.isComplete = false;
        this.onStep = currentStep;
      }
    },
    findNextComponent() {
      let componentName = "";
      switch (this.onStep) {
        case 1:
          componentName = "fill-general-info";
          break;
        case 2:
          componentName = "add-materials";
          break;
        case 3:
          componentName = "add-submission-details";
          break;
        case 4:
          componentName = "add-evaluators";
          break;
      }
      return componentName;
    }
  },
  async created() {
    eventBus.$on("imageUploadCompletedEvent", imageId => {
      this.courseData.instructorPictureId = imageId;
    });
    eventBus.$on("DeleteEvent", () => {
      this.getTheCourses();
    });
    this.accountid = this.currentUser.roles[0].accountId;
    this.headerTitle = this.$route.query.course;
    this.trainingId = this.$route.query.trainingId;
    if (this.$route.query.courseId) {
      this.isNew = false;
      this.courseId = this.$route.query.courseId;
      await this.getTheCourses();
    }
  },
  components: {
    BzNotification,
    BzButton,
    Congratulations,
    "fill-general-info": FillGeneralInfo,
    "add-materials": AddMaterials,
    "add-submission-details": AddSubmissionDetails,
    "add-evaluators": AddEvaluators,
    AdminHeader
  },

  computed: {
    ...mapFields("auth", ["currentUser"]),
    bcLinks() {
      return [
        {
          text: "Modules",
          disabled: false,
          to: {
            name: "modules"
          }
        },
        {
          text: "Elevation Module",
          disabled: false,
          to: {
            name: "elevation-module"
          }
        },
        {
          text: "Training",
          to: {
            name: "elevation-training-card"
          },
          disabled: false
        },
        {
          text: this.headerTitle,
          disabled: false,
          to: {
            name: "course-home",
            query: {
              course: this.headerTitle,
              id: this.trainingId
            }
          }
        },
        {
          text: "Add Course",
          disabled: true
        }
      ];
    },
    routeTo() {
      return {
        name: "course-home",
        course: this.headerTitle,
        id: this.trainingId
      };
    }
  }
};
</script>

<style scoped lang="scss">
.stepper-contianer {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
}
.divCursor {
  cursor: pointer;
}
</style>