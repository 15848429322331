<template>
  <div class>
    <v-dialog
      v-model="dialog"
      max-width="300"
      class="d-flex justify-center text-center align-center"
    >
      <v-card>
        <v-card-title class="primary textLight--text font-weight-thin">
          <div class="text-center headline" style="width:100%">Add Step</div>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="Step Name"
                v-model="step.title"
                :rules="[v => !!v || 'Step Name is required']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                auto-grow
                label="Step Description"
                no-resize
                rows="1"
                :maxlength="maxDescription"
                :counter="maxDescription"
                v-model="step.description"
                :rules="[v => !!v || 'Step Description is required']"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12 pb-0">
              <h4 class="text-center grey--text">Choose File Type</h4>
            </v-col>
            <v-col class="py-0">
              <v-item-group
                active-class="selected"
                v-model="step.icon"
                class="d-flex justify-space-around"
              >
                <v-item
                  v-slot:default="{ active, toggle }"
                  v-for="type of fileTypes"
                  :key="type.type"
                >
                  <div @click="toggle" class="pa-2 file-type">
                    <v-icon>{{type.icon}}</v-icon>
                    <label class="d-block">{{type.type}}</label>
                  </div>
                </v-item>
              </v-item-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 px-8">
              <v-checkbox v-model="step.isOptional" label="Check this if the Step is Optional"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text px-10"
            elevation="0"
            color="textOrange"
            :disabled="!isFormValid"
            :loading="submitLoading"
            @click="submit()"
          >Add Step</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h1 class="textOrange--text body-1">SUBMISSION</h1>
    <v-card height="350" max-width="600" elevation="1" class="mt-4 scroll">
      <v-card-title @click="dialog=true">
        <v-btn text>
          <v-icon class="mr-2 primary--text">mdi-plus</v-icon>
          <span class="primary--text">Add Step</span>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-card
          color="transparent"
          class="d-flex align-center justify-center"
          flat
          height="17  0"
          v-if="steps.length==0"
        >
          <v-card-text class="d-flex justify-center body-1 mb-2">No steps to preview</v-card-text>
        </v-card>
        <v-card
          v-for="(step,i) in steps"
          :key="i"
          class="d-flex ma-5 px-5 pt-3 align-center"
          outlined
          elevation="1"
        >
          <v-icon class="display-1 primary--text mr-5 align-start">{{step.icon}}</v-icon>
          <div>
            <span class="textOrange--text" v-if="step.isOptional">[optional]</span>
            <h1 class="title text-capitalize font-weight-regular">{{step.title}}</h1>
            <p>{{step.description}}</p>
          </div>
          <v-icon class="align-middle ml-auto delete" @click="removeSubmissionStep(i)">mdi-delete</v-icon>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<style scoped>
.scroll {
  overflow-y: auto;
}
</style>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { eventBus } from "@/main";
export default {
  props: ["courseData"],
  data: () => ({
    dialog: false,
    submitLoading: false,
    submissionStepsIds: [],
    maxDescription: 250,
    step: {
      title: "",
      description: "",
      icon: -1,
      fileIcon: "",
      isOptional: false
    },
    steps: [],
    fileTypes: [
      {
        type: "Video",
        icon: "mdi-play-box-multiple"
      },
      {
        type: "Text",
        icon: "mdi-format-size"
      },
      {
        type: "Image",
        icon: "mdi-image"
      },
      {
        type: "Doc",
        icon: "mdi-text-box"
      }
    ]
  }),
  created() {
    this.getSubmissionSteps();
  },
  computed: {
    ...mapFields("training", ["allSubmissionSteps"]),
    isFormValid() {
      return this.step.title && this.step.icon >= 0 && this.step.description;
    }
  },
  methods: {
    ...mapActions("training", {
      addNewSubmission: "addNewSubmission",
      fetchSubmissionSteps: "fetchSubmissionSteps",
      removeSubmissionSteps: "removeSubmissionSteps"
    }),

    attachIcon(fileType) {
      switch (fileType) {
        case "Video":
          this.fileIcon = "mdi-play-box-multiple";
          break;
        case "Text":
          this.fileIcon = "mdi-format-size";
          break;
        case "Image":
          this.fileIcon = "mdi-image";
          break;
        case "Doc":
          this.fileIcon = "mdi-text-box";
          break;
        default:
          this.fileIcon = "";
          break;
      }
    },
    async getSubmissionSteps() {
      try {
        if (this.courseData.submissionSteps.length > 0) {
          this.courseData.submissionSteps.map(element => {
            this.attachIcon(element.fileType);
            this.steps.push({
              title: element.name,
              subtitle: element.description,
              icon: this.fileIcon,
              isOptional: element.isOptional,
              id: element._id
            });
            this.submissionStepsIds.push(element._id);
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async submit() {
      try {
        this.submitLoading = true;

        const submission = await this.addNewSubmission({
          courseId: this.courseData.courseId,
          title: this.step.title,
          description: this.step.description,
          fileType: this.fileTypes[this.step.icon].type,
          isOptional: this.step.isOptional
        });

        let step = {
          title: this.step.title,
          description: this.step.description,
          icon: this.fileTypes[this.step.icon].icon,
          isOptional: this.step.isOptional,
          id: submission._id
        };
        this.courseData.submissionSteps.push({
          name: step.title,
          description: step.description,
          fileType: this.fileTypes[this.step.icon].type,
          fileIcon: step.icon,
          isOptional: submission.isOptional,
          _id: submission._id
        });
        this.submissionStepsIds.push(submission._id);
        this.steps.push(step);
        this.$emit("addStep", {
          title: this.step.title,
          description: this.step.description,
          icon: this.fileTypes[this.step.icon].icon,
          isOptional: this.step.isOptional
        });
      } catch (error) {
        console.log(error.message);
      } finally {
        this.dialog = false;
        this.submitLoading = false;
        (this.step.title = ""),
          (this.step.description = ""),
          (this.step.icon = -1),
          (this.step.isOptional = false);
      }
    },
    async removeSubmissionStep(index) {
      this.submissionStepsIds.splice(index, 1);
      let patchData = {
        courseId: this.courseData.courseId,
        submissionStepsIds: this.submissionStepsIds
      };
      try {
        const removed = await this.removeSubmissionSteps({
          patchData: patchData
        });
        if (removed) {
          this.steps.splice(index, 1);
          eventBus.$emit("DeleteEvent");
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  }
};
</script>

<style>
.selected {
  background-color: #119fc1;
  color: white;
  border-radius: 5px;
}
.file-type:hover {
  cursor: pointer;
  background-color: #119ec194;
}
.delete:hover {
  cursor: pointer;
  color: #fe664e;
}
</style>