<template >
  <div class="criteria-card d-flex justify-space-between px-6">
    <v-col cols="8">
      <div class="text subtitle-1">{{criteria.description}}</div>
    </v-col>
    <v-col cols="4" class="ma-0 pa-0 d-flex flex-row justify-end">
        <div class="textOrange--text px-3">{{rating}}</div>
        <div class="px-3">
          <v-icon right @click="removeCriteria">mdi-window-close</v-icon>
        </div>
    </v-col>
  </div>
</template>

<script>
export default {
  props: ["criteria", "index"],
  computed: {
    rating() {
      if (this.criteria.ratingType === "BOOLEAN") {
        return "Yes/No";
      } else {
        return "1-10";
      }
    }
  },
  methods: {
    removeCriteria: function() {
      this.$emit("removeCriteria", this.index);
    }
  }
};
</script>

<style scoped>
.criteria-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  margin: 10px 20px;
  padding: 0px 10px;
  height: 84px;
  display: grid;
  grid-template-columns: 250px 1fr;
  justify-items: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 80px;
}
</style>