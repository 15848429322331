<template>
  <v-card class="d-flex align-center ml-1 mr-1 material-card" height="84">
    <div class="add-material-icon ml-4 mr-2">
      <v-icon left color="primary" large>mdi-link</v-icon>
    </div>
    <div class="add-material-content">
      <div class="d-flex flex-column">
        <span class="body-1 card-title">{{materialData.title}}</span>

        <span class="body-2 card-subtitle">{{materialData.subtitle}}</span>
      </div>
    </div>
    <div class="close" @click="removeFile">
      <v-icon right>mdi-window-close</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "material-card",
  props: ["materialData", "index"],

  methods: {
    removeFile: function() {
      this.$emit("removeFile", this.index);
    }
  }
};
</script>

<style scoped>
.material-card {
  box-shadow: 0px 0px 10px #0000001a;
  width: 363px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.add-material-icon {
  transform: rotate(90deg);
}

.add-material-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-title {
  height: 19px;
}
.card-subtitle {
  height: 19px;
}

.close {
  justify-self: end;
  margin-right: 15px;
  cursor: pointer;
}
</style>