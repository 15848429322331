<template>
  <div class="card-container">
    <div>
      <avatar :evaluator="evaluator" />
    </div>
    <div class="name-container d-flex flex-column">
      <div class="subtitle-1">{{evaluator.name}}</div>
      <div class="body-2 grey--text">{{evaluator.email}}</div>
    </div>
    <div>
      <v-icon right class="mt-3" @click="removeEvaluator">mdi-window-close</v-icon>
    </div>
  </div>
</template>

<script>
import Avatar from "./Avatar";
export default {
  components: {
    Avatar
  },
  props: ["evaluator", "index"],
  methods: {
    removeEvaluator: function() {
      this.$emit("removeEvaluator", this.index);
    }
  }
};
</script>

<style scoped>
.card-container {
  height: 84px;
  margin: 5px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  display: grid;
  width: 364px;
  grid-gap: 5px;
  grid-template-columns: 80px 1fr;
  grid-auto-flow: column;
  align-content: center;
  grid-auto-columns: 60px;
}
</style>