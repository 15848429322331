<template>
  <div class="add-materials">
    <p>MATERIAL</p>
    <div class="card-container">
      <div class="add-materials-header-container ml-6 pt-4" @click.stop="dialog = true">
        <v-icon left color="primary">mdi-plus</v-icon>
        <p class="mt-4 body-1 primary--text">Add Material</p>
      </div>
      <div class="d-flex justify-center">
        <div class="list-container" v-if="materialCards.length > 0">
          <div v-for="(materialCard, index) in materialCards" :key="index">
            <material-card
              :materialData="materialCard"
              :index="index"
              @removeFile="removeFile($event)"
            />
          </div>
        </div>
        <div
          v-else
          class="d-flex align-center empty-container primary--text"
          @click.stop="dialog = true"
        >Add Materials</div>
      </div>
    </div>

    <v-dialog v-model="dialog" width="350">
      <v-card height="420">
        <v-card-title
          class="headline grey primary d-flex justify-center white--text pb-5"
        >Label Material</v-card-title>

        <v-form
          class="d-flex justify-center flex-column align-center"
          @submit.prevent="addMaterial"
          ref="form"
        >
          <div class="form-container">
            <v-file-input v-model="file" label="File" :rules="[v => !!v || 'File is required']"></v-file-input>

            <v-text-field
              :rules="[v => !!v || 'Title is required']"
              maxlength="25"
              v-model="title"
              :counter="25"
              label="Title"
            ></v-text-field>
            <v-text-field
              :rules="[v => !!v || 'Subtitle is required']"
              v-model="subtitle"
              :counter="35"
              maxlength="35"
              label="Subtitle"
            ></v-text-field>
          </div>

          <div class="button-container">
            <v-btn
              color="textOrange"
              title="Submit"
              type="Submit"
              dark
              block
              :loading="loadmaterial"
              elevation="0"
            >Add Material</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { eventBus } from "@/main";
import MaterialCard from "../AddMaterials/MaterialCard";
export default {
  name: "AddMaterials",
  props: ["courseData"],
  data() {
    return {
      loadmaterial: false,
      dialog: false,
      title: null,
      subtitle: null,
      file: null,
      fileId: null,
      materialCards: [],
      materialIds: []
    };
  },
  components: {
    MaterialCard
  },
  created() {
    this.getAvailableMaterials();
  },
  computed: {
    ...mapFields("training", ["allMaterials"])
  },
  methods: {
    ...mapActions("admin", {
      uploadFile: "uploadFile"
    }),
    ...mapActions("training", {
      addNewMaterial: "addNewMaterial",
      fetchAvailableMaterials: "fetchAvailableMaterials",
      removeMaterial: "removeMaterial"
    }),
    async removeFile(index) {
      this.materialIds.splice(index, 1);
      let patchData = {
        courseId: this.courseData.courseId,
        materialIds: this.materialIds
      };
      try {
        const removed = await this.removeMaterial({
          patchData: patchData
        });
        if (removed) {
          this.materialCards.splice(index, 1);
          eventBus.$emit("DeleteEvent");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    getAvailableMaterials() {
      try {
        if (this.courseData.material.length > 0) {
          this.courseData.material.map(element => {
            this.materialCards.push({
              title: element.name,
              subtitle: element.description,
              id: element._id
            });
            this.materialIds.push(element._id);
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async addMaterial() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.loadmaterial) {
        return;
      }
      try {
        if (this.file) {
          /* file type checker 
         if (file.type.split("/")[0] === "exe") {
            this.notify("error", "An unexpected error has occured"); 
            return;
          } */
          this.loadmaterial = true;
          const uploadedMaterial = await this.uploadFile({
            fileData: this.file
          });
          this.fileId = uploadedMaterial[0]._id;

          this.dialog = false;

          const uploaded = await this.addNewMaterial({
            courseId: this.courseData.courseId,
            title: this.title,
            subtitle: this.subtitle,
            fileId: this.fileId
          });

          this.materialCards.push({
            title: uploaded.name,
            subtitle: uploaded.description,
            id: uploaded._id
          });
          this.courseData.material.push({
            name: uploaded.name,
            description: uploaded.description,
            _id: uploaded._id
          });
          this.$refs.form.reset();
        }
        this.loadmaterial = false;
      } catch (error) {
        // alert(error.message);
      }
    }
  }
};
</script>

<style scoped>
.list-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;

  height: 380px;
}
.card-container {
  box-shadow: 0px 0px 25px #00000017;
  border-radius: 6px;
  background: white;
  min-height: 450px;
  max-height: 400px;

  width: 424px;

  padding-bottom: 20px;
}
.add-materials-header-container {
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  margin-top: 30px;

  width: 282px;
  flex-direction: column;
  align-content: center;
}

.button-container {
  width: 80%;
  margin-top: 30px;
}

.empty-container {
  margin-top: 80px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;

  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dcdcdc;

  opacity: 0.5;
  border-radius: 2px;
}
</style>
