<template>
  <div class="avatar textOrange white--text">{{evaluator.initial}}</div>
</template>

<script>
export default {
  props: ["evaluator"],
};
</script>

<style scoped>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  margin-left: 20px;
}
</style>